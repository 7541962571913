<template>
  <AdminTemplate class="admin-register-list">
    <div class="admin-header">
      <h1>ข้อมูลรายการชำระเงิน</h1>
    </div>
    <el-row :gutter="6">
      <el-col :span="10">
        <el-card class="admin-search-box">
          <el-form :model="form" ref="form">
            <h3>ค้นหาข้อมูลรายการชำระเงิน</h3>
            <el-row :gutter="12">
              <el-col :span="12">
                <el-form-item label="ค้นหา" prop="search">
                  <el-input
                    v-model="form.search"
                    placeholder="ค้นหาเลขใน Payment"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="สถานะ" prop="status">
                  <el-select
                    v-model="form.status"
                    placeholder="สถานะ"
                    class="width-100"
                  >
                    <div slot="prefix">
                      <i
                        class="fa-solid fa-circle"
                        :style="`color:${getPaymentStatusColor(form.status)};`"
                      ></i>
                    </div>

                    <el-option
                      v-for="item in paymentStatus"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    >
                      <span
                        ><i
                          class="fa-solid fa-circle"
                          :style="`color:${item.color};margin-right:5px`"
                        ></i>
                        {{ item.name }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div class="float-right">
                  <el-button
                    @click="search"
                    :loading="loadingList"
                    type="success"
                    size="medium"
                    >ค้นหา</el-button
                  >
                </div></el-col
              >
            </el-row>
          </el-form>
        </el-card>

        <div class="admin-list-box">
          <el-card>
            <h3>รายการชำระเงิน</h3>
            <div v-loading="loadingList" class="user-list">
              <el-row
                :gutter="20"
                v-for="item in payments"
                :key="item.id"
                class="user-item"
                @click="fetchPaymentDetail(item.id)"
              >
                <el-col :span="19" class="content-box">
                  <div class="content-detail-box">
                    <i
                      class="fa-solid fa-circle"
                      :style="`color:${getPaymentStatusColor(item.status)};`"
                    ></i>
                    <div>
                      <p>
                        {{ item.payment_code }}
                        <span class="tag-label">{{
                          item.total_price | commaBath
                        }}</span>
                      </p>

                      <p>
                        {{ item.created_date | dateTHShort }}
                      </p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5" class="action-box">
                  <el-button
                    @click="fetchPaymentDetail(item.id)"
                    type="info"
                    icon="el-icon-user-solid"
                    size="mini"
                    circle
                  ></el-button>
                  <el-popconfirm
                    title="คุณต้องการลบผู้สมัครนี้?"
                    confirm-button-text="ใช่"
                    cancel-button-text="ไม่ใช่"
                    confirm-button-type="info"
                    @confirm="deletePayment(item.payment_code)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      circle
                    ></el-button>
                  </el-popconfirm>
                </el-col>
              </el-row>
              <div v-if="total > 0" class="pagination-box">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :current-page.sync="page"
                  @current-change="handleCurrentChange"
                  :page-size="limit"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>

      <el-col v-if="detail" v-loading="loadingDetail" :span="14">
        <el-row :gutter="12"
          ><el-col :span="12">
            <el-card class="payment-detail-box"
              ><h3>ข้อมูลการชำระเงิน</h3>
              <p><b>เลขใบ Payment:</b> #{{ detail.payment.payment_code }}</p>
              <h4>วิธีการชำระเงิน</h4>
              <p>
                <i class="fa-regular fa-circle-check"></i>
                {{
                  detail.payment.payment_type === "bank_transfer"
                    ? "โอนเงินเข้าบัญชี"
                    : "เช็คธนาคาร"
                }}
              </p>

              <p>
                <b>จำนวนเงิน:</b> {{ detail.payment.total_price | comma }} บาท
              </p>
            </el-card>
          </el-col>
          <el-col :span="12">
            <el-card class="payment-detail-box"
              ><h3>ข้อมูลผู้สมัคร</h3>
              <p>
                <b>ประเภทการสมัคร:</b>
                {{
                  detail.registerType.register_type === "group"
                    ? "สมัครในนามหน่วยงาน/องค์กร/บริษัท (แบบกลุ่ม)"
                    : "สมัครในนามบุคคล (แบบเดี่ยว)"
                }}
              </p>
            </el-card>
            <el-card class="payment-register-box"
              ><h3>รายละเอียดผู้สมัคร</h3>
              <div class="register-list">
                <div
                  v-for="item in detail.registers"
                  :key="item.id"
                  class="register-item"
                >
                  <div class="item">
                    <p>
                      <span>
                        {{
                          `${item.prefix_en} ${item.first_name_en} ${item.last_name_en}`
                        }}
                      </span>
                      <el-tag type="info">{{ item.register_code }}</el-tag>
                    </p>
                    <p><b>ประเภท:</b> {{ "แพทย์" }}</p>
                    <p>
                      <b>ค่าลงทะเบียน (บาท):</b>
                      {{
                        item.registration_fees === "member" ? 3500 : 0 | comma
                      }}
                    </p>
                  </div>
                  <el-divider></el-divider>
                </div>
              </div>
            </el-card> </el-col
        ></el-row>
      </el-col>
    </el-row>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import { status } from "@/maxins/status";
import { HTTP } from "@/plugins/axios.js";
import { auth } from "@/maxins/auth";

export default {
  name: "AdminPayment",
  components: {
    AdminTemplate,
  },
  props: {
    msg: String,
  },
  computed: {},
  mixins: [status, auth],
  data() {
    return {
      form: {
        search: "",
        status: "ทั้งหมด",
      },

      payments: [],
      total: 0,
      limit: 10,
      page: 1,
      loadingList: true,
      loadingDetail: true,
      detail: null,
      fileUploadURL: process.env.VUE_APP_BASE_UPLOAD_FILE,
    };
  },
  mounted() {
    this.fetchPayment();
  },
  methods: {
    search() {
      this.loadingList = true;
      this.page = 1;
      this.fetchPayment();
    },
    handleCurrentChange(val) {
      this.loadingList = true;
      this.page = val;
      this.fetchPayment();
    },
    async fetchPayment() {
      try {
        let obj = {
          search: this.form.search,
          status: this.form.status === "ทั้งหมด" ? "" : this.form.status,
          page: this.page,
          limit: this.limit,
        };
        let res = await HTTP.post(`/payment/get`, obj);

        if (res.data.success) {
          this.total = res.data.data.total;
          this.payments = res.data.data.result;
        }

        this.loadingList = false;
      } catch (error) {
        if (error.response.status === 401) {
          this.logout();
        }
      } finally {
        this.loadingList = false;
      }
    },
    async fetchPaymentDetail(id) {
      this.loadingDetail = true;
      let res = await HTTP.get(`/payment/by/${id}`);

      if (res.data.success) {
        this.detail = res.data.data;
      }

      this.loadingDetail = false;
    },
    async deleteRegister(id) {
      this.loadingList = true;
      let res = await HTTP.delete(`/register/by/${id}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchPayment();
      }

      this.loadingList = false;
    },
    async updatepaymentStatus(id, paymentCode, registerTypeId, status) {
      this.loadingList = true;
      this.loadingDetail = true;

      let obj = {
        payment_code: paymentCode,
        register_type_id: registerTypeId,
        status: status,
      };

      let res = await HTTP.put(`/payment-confirm/status`, obj);

      if (res.data.success) {
        this.$message({
          message: "อัปเดตสถานะสำเร็จ",
          type: "success",
        });
        this.detail = null;
        this.page = 1;
        this.fetchPayment();
        this.fetchPaymentDetail(id);
      }

      this.loadingDetail = false;
      this.loadingList = false;
    },
    async deletePayment(code) {
      this.loadingList = true;
      let res = await HTTP.delete(`/payment/by/${code}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchPayment();
      }

      this.loadingList = false;
    },
  },
};
</script>
