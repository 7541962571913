<template>
  <AdminTemplate class="admin-register-list">
    <div class="admin-header">
      <h1>ยืนยันการชำระเงิน</h1>
    </div>
    <el-row :gutter="6">
      <el-col :span="10">
        <el-card class="admin-search-box">
          <el-form :model="form" ref="form">
            <h3>ค้นหาข้อมูลการลงทะเบียน</h3>
            <el-row :gutter="12">
              <el-col :span="12">
                <el-form-item label="ค้นหา" prop="search">
                  <el-input
                    v-model="form.search"
                    placeholder="ค้นหาจากรหัสลงทะเบียน"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="สถานะ" prop="status">
                  <el-select
                    v-model="form.status"
                    placeholder="สถานะ"
                    class="width-100"
                  >
                    <div slot="prefix">
                      <i
                        class="fa-solid fa-circle"
                        :style="`color:${getpaymentConfirmStatusColor(
                          form.status
                        )};`"
                      ></i>
                    </div>
                    <el-option
                      v-for="item in paymentConfirmStatus"
                      :key="item.name"
                      :label="item.label"
                      :value="item.name"
                    >
                      <span
                        ><i
                          class="fa-solid fa-circle"
                          :style="`color:${item.color};margin-right:5px`"
                        ></i>
                        {{ item.label }}</span
                      >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <div class="float-right">
                  <el-button
                    @click="search"
                    :loading="loadingList"
                    type="success"
                    size="medium"
                    >ค้นหา</el-button
                  >
                </div></el-col
              >
            </el-row>
          </el-form>
        </el-card>

        <div class="admin-list-box">
          <el-card>
            <h3>รายการชำระเงิน</h3>
            <div v-loading="loadingList" class="user-list">
              <el-row
                :gutter="20"
                v-for="item in paymentConfirms"
                :key="item.id"
                class="user-item"
                @click="fetchPaymentConfirmDetail(item.id)"
              >
                <el-col :span="19" class="content-box">
                  <div class="content-detail-box">
                    <i class="fa-solid fa-credit-card"></i>
                    <div>
                      <p>
                        {{ item.payment_code }}
                        <el-tag
                          :style="`background-color:${getpaymentConfirmStatusColor(
                            item.status
                          )};color:#fff;`"
                          >{{
                            getpaymentConfirmStatusLabel(item.status)
                          }}</el-tag
                        >
                      </p>

                      <p>
                        {{ item.create_date | dateTHShort }}
                      </p>
                    </div>
                  </div>
                </el-col>
                <el-col :span="5" class="action-box">
                  <el-button
                    @click="fetchPaymentConfirmDetail(item.id)"
                    type="info"
                    icon="el-icon-edit-outline"
                    size="mini"
                    circle
                  ></el-button>
                  <el-popconfirm
                    title="คุณต้องการลบผู้สมัครนี้?"
                    confirm-button-text="ใช่"
                    cancel-button-text="ไม่ใช่"
                    confirm-button-type="info"
                    @confirm="deletePaymentConfirm(item.payment_code)"
                  >
                    <el-button
                      slot="reference"
                      type="danger"
                      icon="el-icon-delete"
                      size="mini"
                      circle
                    ></el-button>
                  </el-popconfirm>
                </el-col>
              </el-row>
              <div v-if="total > 0" class="pagination-box">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :current-page.sync="page"
                  @current-change="handleCurrentChange"
                  :page-size="limit"
                >
                </el-pagination>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>

      <el-col v-if="detail" v-loading="loadingDetail" :span="14">
        <el-card class="payment-detail-box"
          ><h3>{{ detail.paymentConfirm.payment_code }}</h3>
          <div class="payment-detail-content">
            <div class="image-box">
              <a
                data-fancybox="payment-preview"
                :href="`${fileUploadURL}/${detail.paymentConfirm.img}`"
                class="fancybox"
                ><el-card class="box-card" shadow="hover">
                  <el-image
                    :src="`${fileUploadURL}/${detail.paymentConfirm.img}`"
                    fit="cover"
                  ></el-image>
                </el-card>
              </a>
            </div>

            <div class="data-box">
              <p>
                <b>วันที่ชำระ: </b>
                {{ detail.paymentConfirm.create_date | dateTHShort }}
              </p>

              <div>
                <p>
                  <b>สถานะ: </b>
                </p>
                <el-form :model="formUpdate" ref="form-update">
                  <el-form-item prop="status">
                    <el-select
                      v-model="formUpdate.status"
                      placeholder="สถานะ"
                      class="width-100"
                      @change="
                        (status) =>
                          updatepaymentConfirmStatus(
                            detail.paymentConfirm.id,
                            detail.payment.payment_code,
                            detail.registerType.id,
                            status
                          )
                      "
                    >
                      <div slot="prefix">
                        <i
                          class="fa-solid fa-circle"
                          :style="`color:${getpaymentConfirmStatusColor(
                            formUpdate.status
                          )};`"
                        ></i>
                      </div>
                      <el-option
                        v-for="item in paymentUpdateStatus"
                        :key="item.name"
                        :label="item.label"
                        :value="item.name"
                      >
                        <span
                          ><i
                            class="fa-solid fa-circle"
                            :style="`color:${item.color};margin-right:5px`"
                          ></i>
                          {{ item.label }}</span
                        >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </div>

              <div class="data-detail">
                <h3>ข้อมูลการชำระเงิน</h3>
                <p><b>เลขใบ Payment:</b> #{{ detail.payment.payment_code }}</p>
                <h4>วิธีการชำระเงิน</h4>
                <p>
                  <i class="fa-regular fa-circle-check"></i>
                  {{
                    detail.payment.payment_type === "bank_transfer"
                      ? "โอนเงินเข้าบัญชี"
                      : "เช็คธนาคาร"
                  }}
                </p>

                <p>
                  <b>จำนวนเงิน:</b> {{ detail.payment.total_price | comma }} บาท
                </p>
              </div>
            </div>
          </div>

          <div class="register-box">
            <h3>รายละเอียดผู้สมัคร</h3>
            <div class="register-list">
              <div
                v-for="item in detail.registers"
                :key="item.id"
                class="register-item"
              >
                <div class="item">
                  <p>
                    <el-tag type="info">{{ item.register_code }}</el-tag>
                    <span>
                      {{
                        `${item.prefix_en} ${item.first_name_en} ${item.last_name_en}`
                      }}
                    </span>
                    <span class="tag-label">{{
                      item.registration_fees === "member" ? 3500 : 0 | commaBath
                    }}</span>
                  </p>
                  <p><b>ประเภท:</b> {{ "แพทย์" }}</p>
                </div>
                <el-divider></el-divider>
              </div>
            </div>
          </div>
          <div></div>
        </el-card>
      </el-col>
    </el-row>
  </AdminTemplate>
</template>

<script>
import AdminTemplate from "@/template/AdminTemplate";
import { status } from "@/maxins/status";
import { HTTP } from "@/plugins/axios.js";
import { auth } from "@/maxins/auth";

export default {
  name: "AdminPaymentConfirm",
  components: {
    AdminTemplate,
  },
  props: {
    msg: String,
  },
  computed: {},
  mixins: [status, auth],
  data() {
    return {
      form: {
        search: "",
        status: "wait",
      },
      formUpdate: {
        status: "",
      },
      paymentConfirms: [],
      total: 0,
      limit: 10,
      page: 1,
      loadingList: true,
      loadingDetail: true,
      detail: null,
      fileUploadURL: process.env.VUE_APP_BASE_UPLOAD_FILE,
    };
  },
  mounted() {
    this.fetchPaymentConfirm();
  },
  methods: {
    search() {
      this.loadingList = true;
      this.page = 1;
      this.fetchPaymentConfirm();
    },
    handleCurrentChange(val) {
      this.loadingList = true;
      this.page = val;
      this.fetchPaymentConfirm();
    },
    async fetchPaymentConfirm() {
      try {
        let obj = {
          search: this.form.search,
          status: this.form.status,
          page: this.page,
          limit: this.limit,
        };
        let res = await HTTP.post(`/payment-confirm/get`, obj);

        if (res.data.success) {
          this.total = res.data.data.total;
          this.paymentConfirms = res.data.data.result;
        }

        this.loadingList = false;
      } catch (error) {
        if (error.response.status === 401) {
          this.logout();
        }
      } finally {
        this.loadingList = false;
      }
    },
    async fetchPaymentConfirmDetail(id) {
      this.loadingDetail = true;
      let res = await HTTP.get(`/payment-confirm/by/${id}`);

      if (res.data.success) {
        this.detail = res.data.data;

        this.formUpdate.status = this.detail.paymentConfirm.status;
      }

      this.loadingDetail = false;
    },
    async deleteRegister(id) {
      this.loadingList = true;
      let res = await HTTP.delete(`/register/by/${id}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchPaymentConfirm();
      }

      this.loadingList = false;
    },
    async updatepaymentConfirmStatus(id, paymentCode, registerTypeId, status) {
      this.loadingList = true;
      this.loadingDetail = true;

      let obj = {
        payment_code: paymentCode,
        register_type_id: registerTypeId,
        status: status,
      };

      let res = await HTTP.put(`/payment-confirm/status`, obj);

      if (res.data.success) {
        this.$message({
          message: "อัปเดตสถานะสำเร็จ",
          type: "success",
        });
        this.detail = null;
        this.page = 1;
        this.fetchPaymentConfirm();
        this.fetchPaymentConfirmDetail(id);
      }

      this.loadingDetail = false;
      this.loadingList = false;
    },
    async deletePaymentConfirm(code) {
      this.loadingList = true;
      let res = await HTTP.delete(`/payment-confirm/by/${code}`);

      if (res.data.success) {
        this.$message({
          message: "ลบสำเร็จ",
          type: "success",
        });
        this.page = 1;
        this.fetchPaymentConfirm();
      }

      this.loadingList = false;
    },
  },
};
</script>
